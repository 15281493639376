import axios from "axios";
import { AnalyticsEvent } from "@web/types/enums/AnalyticsEvent";
import { getDefaultLogProperties } from "@web/utils/analytics/getDefaultLogProperties";
import { toObject, emptyFunction } from "shared";
import { getRestUrl } from "@web/utils/env/getRestUrl";

export function logEvent(event: AnalyticsEvent, data?: Record<string, any>) {
  const properties = {
    ...(data == null ? {} : toObject(data)),
    ...getDefaultLogProperties(),
  };

  axios
    .post(getRestUrl("logEventToLoki"), {
      event,
      properties,
    })
    // Swallow errors
    .catch(emptyFunction);
}
