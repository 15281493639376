import { Maybe } from "shared";
import { Dimensions } from "@web/types/Dimensions";
import { getVideoDimensions } from "@web/utils/assets/getVideoDimensions";
import { getImageDimensions } from "@web/utils/assets/getImageDimensions";

export async function getAssetDimensions(
  file: File,
  fileDataUrl: string
): Promise<Maybe<Dimensions>> {
  if (file.type.includes("video")) {
    return getVideoDimensions(fileDataUrl);
  }
  if (file.type.includes("image")) {
    return getImageDimensions(fileDataUrl);
  }
  return null;
}
