import { SeverityLevel } from "@sentry/nextjs";
import { AnalyticsEvent } from "@web/types/enums/AnalyticsEvent";
import { getDefaultLogProperties } from "@web/utils/analytics/getDefaultLogProperties";
import { getErrorEventSeverity } from "@web/utils/analytics/getErrorEventSeverity";
// import logEvent from "utils/analytics/logEvent";
import { logSentryError } from "@web/utils/analytics/logSentryError";
import { toObject, getErrorPropertiesToLog, Environment } from "shared";
import { getEnvironment } from "@web/utils/env/getEnvironment";

export function logError(
  event: AnalyticsEvent,
  error: Error | string,
  data?: { [key: string]: any },
  severityOverride?: SeverityLevel
) {
  if (getEnvironment() !== Environment.Production) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  const errorProperties = getErrorPropertiesToLog(error);

  const severity =
    severityOverride != null
      ? severityOverride
      : getErrorEventSeverity(event, error);

  const properties = {
    ...(data == null ? {} : toObject(data)),
    ...errorProperties,
    severity,
  };
  // logEvent(event, properties);
  logSentryError(
    event,
    error,
    {
      ...properties,
      event,
      ...getDefaultLogProperties(),
    },
    severity
  );
}
