import * as Sentry from "@sentry/nextjs";
import { SeverityLevel } from "@sentry/nextjs";
import { AnalyticsEvent } from "@web/types/enums/AnalyticsEvent";
import { getErrorPropertiesToLog } from "shared";

function getFingerprint(event: AnalyticsEvent, e: Error | string) {
  if (event === AnalyticsEvent.TopLevelEvent) {
    return [event, getErrorPropertiesToLog(e).errorMessage];
  }
  return [event];
}

export function logSentryError(
  event: AnalyticsEvent,
  e: Error | string,
  extra?: { [key: string]: any },
  severity: SeverityLevel = "error"
) {
  console.log("calling captureException");
  const foo = Sentry.captureException(
    typeof e === "string" ? `${event}—${e}` : e,
    {
      extra,
      fingerprint: getFingerprint(event, e),
      level: severity,
      tags: {
        event,
      },
    }
  );
  console.log("done calling captureException", foo);
}
